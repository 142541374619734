import { Observable } from "rxjs";
import { Joint } from "src/app/domain/models/joint";
import { Plant } from "src/app/domain/models/plant";

export abstract class IPlantService {
    abstract getAllPlants(): Observable<Plant[]>;
    abstract getPlantDetails(plantId: string): Observable<Plant | undefined>;
    abstract getPlantDrawings(plant: Plant): Observable<string[]>;
    abstract getPlantImage(plant: Plant): Observable<string>;
    abstract getPlantJoints(plantId: string): Observable<Joint[]>;
    abstract deletePlantImage(image: string): Observable<boolean>;
    abstract deletePlantDrawing(drawing: string): Observable<boolean>;
}